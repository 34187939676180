@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100..700;1,100..700&family=IBM+Plex+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,300;1,400;1,500;1,600;1,700&display=swap');

$primary-font: 'IBM Plex Serif', serif !important;
$secondary-font: 'IBM Plex Sans', sans-serif !important;

// Default font settings
* {
  font-family: $primary-font;
  font-feature-settings: 'liga' off, 'clig' off;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  font-family: $primary-font;
  font-feature-settings: 'liga' off, 'clig' off;
  background-color: #eaeef2;
}

body {
  height: 100% !important;
  font-family: $primary-font;
  font-feature-settings: 'liga' off, 'clig' off;
  margin: 0 !important;
  background-color: #eaeef2;

  ::-webkit-scrollbar {
    display: none;
  }
}

.promotions-theme {
  font-family: $secondary-font !important;
}

/* Styling for tables */
.ag-theme-alpine .ag-cell {
  border-right: 1px solid #ccc !important;
  border-bottom: 1px solid #ccc !important;
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ag-theme-alpine .ag-header-cell {
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ag-theme-alpine .ag-row {
  border: none;
}
/* Ensure the spreadsheet is visible */
